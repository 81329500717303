html {
  scroll-behavior: smooth;
}

:root,
html,
body {
  @apply bg-white;
}

*,
*::after,
*::before {
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  font-family: "Poppins";

  @apply text-16;
}

th,
td {
  @apply p-5;
  @apply text-left;
}

td:last-child {
  @apply text-center;
}

input,
input:focus {
  @apply bg-transparent;
  @apply outline-none;
}

textarea,
textarea:focus {
  @apply bg-transparent;
  @apply outline-none;
}

button,
button:focus,
*:focus {
  outline: none !important;
}

.center {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.between {
  @apply flex;
  @apply justify-between;
  @apply items-center;
}

.p-side {
  @apply px-20;
  @apply lg:px-30;
}

.page {
  @apply p-10;
  @apply md:p-20;
  @apply lg:p-30;
  @apply mt-[128px];
  @apply min-h-[calc(100vh-128px)];
}

.trade {
  @apply lg:min-h-[calc(100vh-128px)];
}

.trade-small {
  @apply lg:min-h-[calc(100vh-188px)];
}

::-webkit-scrollbar {
  @apply w-0;
  @apply h-0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.text-shadow {
  text-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
}
